import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable} from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../src/environments/environment';
/*import config from '../../../assets/config.json'*/

let URL = environment.apiUrl
const HEADERS = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GlobalsService {
  readonly baseAPIUrl: string = URL 
}

@Injectable()
export class LoopBackAuth{
  constructor(){}
  save(data){
    localStorage.setItem('LoopBackAuth', JSON.stringify(data))
  }

  load(){
    return JSON.parse(localStorage.getItem('LoopBackAuth'))
  }
  remove(){
    localStorage.removeItem('LoopBackAuth')
  }
}


@Injectable()
export class empresaService{
  
  constructor(
    private http: HttpClient, 
    private LoopBackAuth :  LoopBackAuth){ }
  findId(id){
    return this.http.get(URL+"v1/admEmpresa/"+id)
  }
  findMenuAll(){
    return this.http.get<any[]>(URL+"v1/admEmpresa/menu")
  }
  update(admEmpresa:any){
    console.log("Datos enviados a servicio -->",admEmpresa)
    return this.http.post<any>(URL+"v1/admEmpresa",admEmpresa, HEADERS)
  }

  uploadFirmaElectronica(firma:any){
    console.log(JSON.stringify(firma))
    return this.http.post<any>(URL+"v1/firmaElectronica",firma, HEADERS)
  }

  periodos(){
    return this.http.get<any>(URL+"v1/periodos/"+this.LoopBackAuth.load().empresa.empCodigo)
  }

  testMail(){
    return this.http.post<any>(URL+"v1/testMail",this.LoopBackAuth.load().empresa.empCodigo, HEADERS)
  }
  validarDatosConfiguracion(){
    return this.http.get<any>(URL+"v1/validarDatosConfiguracion/"+this.LoopBackAuth.load().empresa.empCodigo)
  }
}

@Injectable()
export class userService{
  private estasAutenticado = false
  constructor(
    private http: HttpClient, 
    private LoopBackAuth :  LoopBackAuth){ }

  getEstasAutenticado(){
    return this.estasAutenticado
  }
  setEstasAutenticado(valor){
    this.estasAutenticado = valor
  }

  login(usuario){
    console.log(JSON.stringify(usuario))
    return this.http.post<any>(URL+"v1/login",usuario, HEADERS)
  }
  empresaUsuario(usuario){
    console.log("Empresa Usuario -->",JSON.stringify(usuario))
    return this.http.post<any>(URL+"v1/empresaUsurio",usuario, HEADERS)
  }
  cambioClave(usuario){
    return this.http.post<any>(URL+"v1/cambioClave",usuario, HEADERS)
  }

  resetClave(correo:string, tipoBeneficiario:string){
    console.log(URL+"v1/resetClave/"+correo+"/"+tipoBeneficiario)
    return this.http.get(URL+"v1/resetClave/"+correo+"/"+tipoBeneficiario)
  }
  
}

@Injectable()
export class listadoService{
  constructor(
    private http: HttpClient, 
    private LoopBackAuth :  LoopBackAuth){ }


    getBuscarBeneficiario(identificacion:string, tipoBenficiario:string ){
      let tipoIdentificacion = (/^[0-9]+$/.test(identificacion)) ? 1 : 2
      return this.http.get<any[]>(URL+"v1/buscarBeneficiario/"+this.LoopBackAuth.load().empresa.empCodigo +"/"+tipoBenficiario+"/"+tipoIdentificacion+"/"+identificacion).pipe(
        map(response => response)
      )
    }

    getBuscarTipoIdentificacion(tipoBenficiario:string){
      console.log("Datos de tipo Identificacion envio -->",tipoBenficiario, this.LoopBackAuth.load().empresa.perCodigo)
      return this.http.get<any[]>(URL+"v1/buscarTipoIdentificacion/"+this.LoopBackAuth.load().empresa.perCodigo +"/"+tipoBenficiario).pipe(
        map(response => response)
      )
    }
    getBuscarProducto( identificacion:string, tipoBusqueda:string = '1'){
      return this.http.get<any[]>(URL+"v1/buscarProducto/"+this.LoopBackAuth.load().empresa.empCodigo +"/"+tipoBusqueda+'/'+identificacion).pipe(
        map(response => response)
      )
    }
    getBuscarFormaPago(fecha){
      return this.http.get<any[]>(URL+"v1/buscarFormaPago/"+fecha).pipe(
        map(response => response)
      )
    }
}

@Injectable()
export class validacionService{
  constructor(
    private http: HttpClient, 
    private LoopBackAuth :  LoopBackAuth){ }

    getValidarIdentificacion(tipo:string,identificacion:string){
      console.log("Validar Identificacion",tipo, identificacion)
      return this.http.get<any[]>(URL+"v1/validarIdentificacion/"+tipo +"/"+identificacion).pipe(
        map(res => res)
      )
    }
}

@Injectable()
export class documentosService{
  constructor(
    private http: HttpClient, 
    private LoopBackAuth :  LoopBackAuth){ }
    
    findFechaInicioFechaFin(fechaInicio, fechaFin, sucursal,idUsuario){
      console.log(fechaInicio, fechaFin, sucursal,idUsuario,this.LoopBackAuth.load().tipo)
      return this.http.get<any[]>(URL+"v1/documentos/"+fechaInicio+"/"+fechaFin+"/"+sucursal+"/"+idUsuario+"/"+this.LoopBackAuth.load().tipo).pipe(
        map(response => response)
      )
    }

    enviarmail(idDocumento:any,para:any,asunto:any,estado:any,cuerpo:any, tipodDocumento:any){
      let params ={
        idDocumento:idDocumento,
        para:para,
        asunto:asunto,
        estadoDocumento: estado,
        idEmpresa: this.LoopBackAuth.load().empresa.empCodigo,
        cuerpo:cuerpo,
        tipodDocumento: tipodDocumento
      }
      console.log("Mail -->",JSON.stringify(params))
      return this.http.post(URL+"v1/electronico/sendMail",params,HEADERS)
    }

    log(){
      return this.http.get<any>(URL+"v1/electronico/auditoria")
    }

    cuerpoMail(idDocumento :String, tipoDocumento:String){
      return this.http.get<any>(URL+"v1/electronico/cuerpoMail/"+idDocumento+"/"+tipoDocumento)
    }

    procesarDocumentos(ids){
      let params = ids
      return this.http.post(URL+"v1/electronico/procesar",params,HEADERS)
    }

    procesarmails(ids){ 
      return this.http.post(URL+"v1/electronico/procesarMails",ids,HEADERS)
    }


    insertarFactura(cabecera:any){
      let params = cabecera
      return this.http.post(URL+"v1/electronico/insertarFactura",params,HEADERS)
    }

    estadisticaDocumentos(idPeriodo:string,idBodega:string){
      return this.http.get<any>(URL+"v1/electronico/estadistica/"+this.LoopBackAuth.load().empresa.empCodigo+"/"+idPeriodo+"/"+idBodega)
    }

    getInvDocumento(id: string){
      return this.http.get<any>(URL+"v1/findDocumento/"+id)
    }

    setCrudInvDocumento(documento:any){
      console.log("Datos inv documento -->", JSON.stringify(documento))
      return this.http.post(URL+"v1/electronico/findDocumento",documento,HEADERS)
    }
  
}
