<h2 mat-dialog-title class="f-16 bg-primary text-white pl-2">{{data.titulo}}</h2>
<mat-dialog-content >
    <div style="width: 40px; margin: auto;">
        <i class="{{data.icono}}" style="font-size: 60px;" [ngStyle]="{'color': data.colorIcono}"></i>
    </div>
    
    <div  [innerHTML]="data.mensaje" style="text-align: justify;"></div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()" class="ml-1 mr-1">
        <i class="material-icons">cancel</i>  Cancelar
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data.id" class="ml-1 mr-1">
        <i class="material-icons">check_circle_outline</i> Aceptar 
    </button>
</mat-dialog-actions>