import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {RouterModule} from '@angular/router';
import {AppRoutes} from './app.routing'
import {SharedModule} from './shared/shared.module';
import { AuthComponent } from './layout/auth/auth.component'

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MainNavComponent } from './layout/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { BsDropdownModule } from 'ngx-bootstrap';

import { 
  MatToolbarModule, 
  MatButtonModule, 
  MatSidenavModule, 
  MatIconModule, 
  MatListModule } from '@angular/material';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    MainNavComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes),
    BrowserAnimationsModule,
    SharedModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    BsDropdownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
