
<div class="barra-superios-fixed d-flex justify-content-between">
    <div class="ml-2 icono-menu" (click)="ocultar = !ocultar" >
        <i class="fas fa-bars f-20"></i>
    </div>
    <div class="titulo-barra-superios">
       <h1>{{titulo}}</h1>
    </div>
    <div class="caja-barra">
        <div class="circulo"> {{LoopBackAuth.load().usuario.usuario | slice:0:2}}</div>
        <h1>{{LoopBackAuth.load().usuario.usuario}}</h1>
        <h2>{{LoopBackAuth.load().tipo}}</h2>
    </div>
</div>

<div class="sidebar" [ngStyle]="{'width': ocultar ? '50px' :'200px'}" >
  <div class="sidebar-inner">
    <ul class="nav menu-vertical " [ngClass]="{'mini-menu': ocultar}">
      <li class="nav-item" *ngFor="let item of menus">
        <a 
          class="sidebar-link"
          [ngClass]="{'bg-primary text-white':item.activo}" 
          [routerLink]="[item.router]"
          (click)="activarMenu(item)"
          *ngIf="item.children.length == 0"
          title="{{item.descripcion}}">
          <span class="icon-holder" >
            <i class="{{item.icono}}" ></i>
          </span>
          <span class="title" [hidden]="ocultar">{{item.descripcion}}</span>
        </a>
        <a 
          class="sidebar-link"
          href="javascript:void(0)"
          (click)="item.status = !item.status"
          *ngIf="item.children.length > 0" title="{{item.descripcion}}">
          <span class="icon-holder">
            <i class="{{item.icono}}" ></i>
          </span>
          <span class="title" [hidden]="ocultar">{{item.descripcion}}</span>
          <span class="icon-descarga" [hidden]="ocultar">
            <i class="fas fa-chevron-down"></i>
          </span>
        </a>
        <ul 
          class="nav sub-menu" 
          [ngStyle]="{'display':item.status ? 'block':'none'}"
          *ngIf="item.children.length > 0">
          <li class="nav-item" *ngFor="let subItem of item.children">
            <a 
              class="nivel-2 " 
              href="javascript:void(0)"
              *ngIf="subItem.children.length == 0">
              <span class="icon-holder">
                <i class="fas fa-chevron-right"></i>
              </span>
              <span class="title">{{subItem.descripcion}}</span>
            </a>
  
            <a 
              class="nivel-2" 
              href="javascript:void(0)"
              (click)="subItem.status = !subItem.status"
              *ngIf="subItem.children.length > 0">
              <span class="icon-holder">
                <i class="fas fa-chevron-right"></i>
              </span>
              <span class="title">{{subItem.descripcion}}</span>
              <span class="icon-descarga">
                <i class="fas fa-chevron-down"></i>
              </span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <img 
      [src]="LoopBackAuth.load().empresa.empLogo" 
      class="img-fluid pl-4 pr-4 imagen-left" 
      [hidden]="ocultar">
  </div>
</div>

<div class="contenedor" [ngStyle]="{'padding-left': ocultar ? '50px' :'200px'}">
  <div class="contenido-paginas main-contenido">
    <router-outlet></router-outlet>
  </div>
</div>




<!--mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    style="width: 230px;"
    fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">
    <mat-toolbar color="primary" class="d-flex justify-content-center">
      <img [src]="LoopBackAuth.load().empresa.empLogo" class="img-fluid" width="100" [style.margin]="'2px 0px'">
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="[menu.router]" *ngFor="let menu of menus">
          <mat-icon class="sidenav-icon" [ngStyle]="{'color':(menu.color)? menu.color :'#666' }">{{menu.icono}}</mat-icon> &nbsp;
          <span class="f-12"> {{menu.descripcion}} </span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="d-flex justify-content-start">
        <h1> {{LoopBackAuth.load().empresa.empRazonSocial}}</h1>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="btn-group" dropdown>
          <button 
            id="button-basic" 
            dropdownToggle type="button" 
            class="btn btn-info dropdown-toggle pt-1 pb-1 pl-2 pr-2 bg-white text-dark"
            [style.font-size]="'12px'"
                  aria-controls="dropdown-basic">
            {{empresa.bodSerie1}} {{empresa.bodSerie2}} {{empresa.bodDescripcion}} <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
              role="menu" aria-labelledby="button-basic">
            <li role="menuitem" *ngFor="let empresa of empresas">
              <a 
                class="dropdown-item "
                [style.font-size]="'12px'" 
                href="javascript:void(0)" 
                (click)="cambiarEmpresa(empresa)">
                  {{empresa.bodSerie1}} {{empresa.bodSerie2}} {{empresa.bodDescripcion}}
              </a>
            </li>
            
          </ul>
      </div 
    </mat-toolbar>

    <div class="main-contenido">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container -->
