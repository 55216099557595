import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatTreeModule,} from '@angular/material';
import { FormEmisorComponent } from './element/form-emisor.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FormFirmaDigitalComponent } from './element/form-firma-digital.component';
import { FormCorreoSmtpComponent } from './element/form-correo-smtp.component';
import { ModalErrorDocumentoComponent } from './dialog/modal-error-documento.component';
import { ModalEnviarMailComponent } from './dialog/modal-enviar-mail.component';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormImagenDocumentosComponent } from './element/form-imagen-documentos.component';
import { MenuAdminComponent } from './menu/menu-admin/menu-admin.component';
import { SpinnerMaterialComponent } from './spinner/spinner-material.component';
import {LoopBackAuth, userService, validacionService} from '../../../services/api-rest.service';
import { ModalCortarImagenComponent } from './dialog/modal-cortar-imagen.component';
import { ModalLogInsertarFacturaComponent } from './dialog/modal-log-insertar-factura.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxEditorModule } from 'ngx-editor';
import {NgPipesModule,LeftPadPipe} from 'ngx-pipes';
import { RellenarIzquierdaDirective } from './directive/rellenar-izquierda.directive';
import { InputNumeroDirective } from './directive/input-numero.directive';
import { NumeroDecimalDirective } from './directive/numero-decimal.directive';
import { ValidarCedulaDirective } from './directive/validar-cedula.directive';
import {ModalConfirmacionComponent} from './dialog/modal-confirmacion/modal-confirmacion.component'


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ImageCropperModule,
    NgxJsonViewerModule,
    NgxEditorModule,
    NgPipesModule,

  ],
  exports:[
    HttpClientModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    FormEmisorComponent,
    FormFirmaDigitalComponent,
    FormCorreoSmtpComponent,
    ModalErrorDocumentoComponent,
    ImageCropperModule,
    FormImagenDocumentosComponent,
    MenuAdminComponent,
    SpinnerMaterialComponent,
    ModalLogInsertarFacturaComponent,
    NgPipesModule,
    RellenarIzquierdaDirective,
    InputNumeroDirective,
    NumeroDecimalDirective,
    ValidarCedulaDirective,
    ModalConfirmacionComponent
  ],
  declarations: [
    FormEmisorComponent, 
    FormFirmaDigitalComponent, 
    FormCorreoSmtpComponent, 
    ModalErrorDocumentoComponent, 
    ModalEnviarMailComponent, 
    FormImagenDocumentosComponent, 
    MenuAdminComponent, 
    SpinnerMaterialComponent, 
    ModalCortarImagenComponent, 
    ModalLogInsertarFacturaComponent, 
    RellenarIzquierdaDirective, 
    InputNumeroDirective, 
    NumeroDecimalDirective, 
    ValidarCedulaDirective,
    ModalConfirmacionComponent
  ],
  providers:[
    LoopBackAuth, 
    userService,
    LeftPadPipe,
    validacionService
  ],
  entryComponents:[
    ModalCortarImagenComponent, 
    ModalLogInsertarFacturaComponent,
    ModalEnviarMailComponent,
    ModalConfirmacionComponent
  ]
})
export class SharedModule { }
