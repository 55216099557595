
    
    <div mat-dialog-content class="pt-1 pl-2 pr-2 pb-0 mb-0">
      <h1 class="pl-2 pr-2 f-16">Documento</h1>
      <table class="table table-sm pb-0 mb-0">
        <tbody>
          <tr>
            <td class="bg-primary text-white f-12"> Nº: </td>
            <td class="f-12"> {{data.documento}}</td>
            <td class="bg-primary text-white f-12"> Fecha Envio:</td>
            <td class="f-12">{{data.fecha}}</td>
          </tr>
          <tr>
            <td class="bg-primary text-white f-12"> Identificación:</td>
            <td class="f-12">{{data.cedula}}</td>
            <td class="bg-primary text-white f-12"> Beneficiario: </td>
            <td class="f-12"> {{data.beneficiario}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div mat-dialog-content class="pt-1 pl-2 pr-2">
      <!-- mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="f-12">
              Datos recibidos
            </mat-panel-title>
            <mat-panel-description class="f-12">
              información enviada al servicio
            </mat-panel-description>
            
          </mat-expansion-panel-header>
          <ngx-json-viewer [json]="factura"></ngx-json-viewer>
        </mat-expansion-panel>
      </mat-accordion -->
      <mat-card class="pt-2 pb-2 d-flex justify-content-between">
        <div class="f-12">
          {{data.accion}}
        </div>
        <div class="text-secondary f-12">
          {{data.descripcion}}
        </div>
      </mat-card>
      <mat-card *ngFor ="let item of data.children" class="pt-2 pb-2 d-flex justify-content-between">
        <div class="f-12">
          {{item.accion}}
        </div>
        <div class="text-secondary f-12">
          {{item.descripcion}}
        </div>
      </mat-card>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button (click)="onNoClick()" color="accent">
        <mat-icon aria-label="" >close</mat-icon> Cancelar
      </button>
    </div>
  