import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {LoopBackAuth } from '../../../../services/api-rest.service';
@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
})
export class MainNavComponent {
  menus: any
  empresas: any
  titulo: String
  height = window.screen.height-150+'px'
  width = window.screen.width
  ocultar = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
    
  constructor(
    private breakpointObserver: BreakpointObserver, 
    public LoopBackAuth: LoopBackAuth) {
    this.empresas = LoopBackAuth.load().empresas
    let empresa = LoopBackAuth.load().empresa
    this.titulo = this.LoopBackAuth.load().empresa.empRazonSocial
    let menus = [
      {
        id:1,
        descripcion:'Panel Pricipal',
        icono:'fas fa-tachometer-alt',
        color:'#ff3366',
        router:'/dashboard',
        params: null,
        roles:['ADM'],
        children:[],
        activo:false
      },
      {
        id:2,
        descripcion:'Panel Pricipal',
        icono:'fas fa-tachometer-alt',
        color:'#ff3366',
        router:'/dashboard/empleado',
        params: null,
        roles:['NOM'],
        children:[],
        activo:false
      },
      {
        id:3,
        descripcion:'Documentos Electronicos',
        icono:'fas fa-file-invoice',
        color:'#4680ff',
        router:'/documento',
        params: null,
        roles:['ADM','CLI'],
        children:[],
        activo:false
      },
      /*{
        descripcion:'Nuevos Documentos',
        icono:'fas fa-edit',
        color:'#4680ff',
        router:'/documento/factura/0',
        params: null,
        roles:['ADM'],
        children:[],
        activo:false
      },*/
       {
        id:4,
        descripcion:'Pensiones',
        icono:'fas fa-file-invoice-dollar',
        color:'#4680ff',
        router:'/pension/exportar',
        params: null,
        roles:['ADM'],
        children:[],
        activo:false
      },
      {
        id:5,
        descripcion:'Nomina',
        icono:'fas fa-file-invoice',
        color:'#4680ff',
        router:'/nomina/administrador',
        params: null,
        roles:['ADM'],
        children:[],
        activo:false
      },
      {
        id:6,
        descripcion:'Nomina',
        icono:'fas fa-file-invoice',
        color:'#4680ff',
        router:'/nomina/empleado',
        params: null,
        roles:['NOM'],
        children:[],
        activo:false
      },
      {
        id:7,
        descripcion:'Auditoria',
        icono:'fas fa-archive',
        color:'#4680ff',
        router:'/documento/auditoria',
        params: null,
        roles:['ADM'],
        children:[],
        activo:false
      },
      {
        id:8,
        descripcion:'Configuración',
        icono:'fas fa-cog',
        color: '#FC6180',
        router:'/configuracion',
        params: null,
        roles:['ADM'],
        children:[],
        activo:false
      },
      {
        id:9,
        descripcion:'Cambio de clave',
        icono:'fas fa-unlock-alt',
        color:'#93BE52',
        router:'/usuario/cambio-clave',
        params: null,
        roles:['ADM','CLI','NOM'],
        children:[],
        activo:false
      },
      {
        id:10,
        descripcion:'Salir',
        icono:'fas fa-sign-out-alt',
        color: null,
        router:'/usuario/salir',
        params: null,
        roles:['ADM','CLI','NOM'],
        children:[],
        activo:false
      }

    ]

    if(empresa.tipoEmpresa == "BETHLEMITA"){
      if(empresa.accesoModulo > 0) {
        if(empresa.accesoModulo == 1) /** pencion = 4*/
          menus = menus.filter((x:any) =>  x.id != 5)
        if(empresa.accesoModulo == 2) /** nomina  = 5*/
          menus = menus.filter((x:any) =>  x.id != 4)
        /** accesoModulo > 2 Se muestran los dos */
      }else{
        menus = menus.filter((x:any) =>  x.id != 4 && x.id != 5)
      }
    }else{
      menus = menus.filter((x:any) => x.id != 4 && x.id != 5 && x.id !=6)
    }
      
    this.menus = this.validarAcceso(menus)
  }
  
  validarAcceso(menus:any) { 
      let filtro  =[]
      for(let item of menus){
        if( item.roles.find(x=> x==this.LoopBackAuth.load().tipo)){
          filtro.push(item)
        }
      }

      return filtro
  }

  public activarMenu(item:any){
    for(let menu of this.menus){
      menu.activo = false
    }
    item.activo = true
  }

  cambiarEmpresa(item){
      let data  = this.LoopBackAuth.load()
      data.empresa = item
      this.LoopBackAuth.save(data)
  }
  
}
