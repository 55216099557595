import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
@Component({
    selector: 'modal-confirmacion',
    templateUrl: './modal-confirmacion.component.html',
    styleUrls: ['./modal-confirmacion.component.scss'],
  })

export class ModalConfirmacionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any ,
  ){}
    ngOnInit() {
      this.data.icono = (this.data.icono == undefined) ? "fas fa-times" : this.data.icono
      this.data.colorIcono = (this.data.colorIcono == undefined) ? "#000" : this.data.colorIcono
    }

    onNoClick(): void {
      this.dialogRef.close();
  }
}