
  <!-- h1 
    mat-dialog-title 
    style="font-size:14px; background:#004b9b; padding-left:10px; color:#fff" >Enviar mail</h1 -->
  <div mat-dialog-content class="pt-1 pl-2 pr-2">
    <div class="row m-0">
      <!-- div class="col-12">
        <mat-form-field>
          <input matInput placeholder="De" [(ngModel)]="de" [disabled] ="true">
        </mat-form-field>
      </div -->
      <div class="col-12">
        <mat-form-field>
          <input matInput placeholder="Para" [(ngModel)]="para">
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <input matInput placeholder="Asunto" [(ngModel)]="asunto">
        </mat-form-field>
      </div>
      <div class="col-12 p-0">
      <app-ngx-editor [config]="editorConfig" [placeholder]="'Ingresa tu mensaje'" [spellcheck]="true" [(ngModel)]="cuerpo"></app-ngx-editor>
      </div>
    </div>

  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" color="accent">
    <mat-icon aria-label="" >close</mat-icon> Cancelar
    </button>
    <button mat-raised-button (click)="onEnviarMail()" color="primary">
     <mat-icon aria-label="" [hidden]="!hidden">email</mat-icon> 
     <i class="fa fa-spinner fa-spin" [hidden]="hidden" ></i> 
     Enviar </button>
  </div>
  