import { Component, OnInit, Inject, Input } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import{ LoopBackAuth, documentosService} from '../../../../services/api-rest.service';
import { ToastrService } from 'ngx-toastr';
import { isEmpty } from 'rxjs/operators';


@Component({
  selector: 'modal-enviar-mail',
  template: `
  <!-- h1 
    mat-dialog-title 
    style="font-size:14px; background:#004b9b; padding-left:10px; color:#fff" >Enviar mail</h1 -->
  <div mat-dialog-content class="pt-1 pl-2 pr-2">
    <div class="row m-0">
      <!-- div class="col-12">
        <mat-form-field>
          <input matInput placeholder="De" [(ngModel)]="de" [disabled] ="true">
        </mat-form-field>
      </div -->
      <div class="col-12">
        <mat-form-field>
          <input matInput placeholder="Para" [(ngModel)]="para">
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <input matInput placeholder="Asunto" [(ngModel)]="asunto">
        </mat-form-field>
      </div>
      <div class="col-12 p-0">
      <app-ngx-editor [config]="editorConfig" [placeholder]="'Ingresa tu mensaje'" [spellcheck]="true" [(ngModel)]="cuerpo"></app-ngx-editor>
      </div>
    </div>

  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" color="accent">
    <mat-icon aria-label="" >close</mat-icon> Cancelar
    </button>
    <button mat-raised-button (click)="onEnviarMail()" color="primary">
     <mat-icon aria-label="" [hidden]="!hidden">email</mat-icon> 
     <i class="fa fa-spinner fa-spin" [hidden]="hidden" ></i> 
     Enviar </button>
  </div>
  `,
  styles: []
})
export class ModalEnviarMailComponent implements OnInit {
  de:String = "ricardodaniel83@gmail.com"
  para: String
  asunto: String = "Documento Electronico "
  cuerpo: String =""
  hidden = true
  editorConfig
  constructor(
    public dialogRef: MatDialogRef<ModalEnviarMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any ,
    private documentosService: documentosService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.editorConfig = {
      "height": "200px",
      "placeholder": "'Redacte su correo'",
      "imageEndPoint": "http://myApiEndPoint",
      "toolbar": [
        ["bold", "italic", "underline", "justifyLeft", "justifyCenter", "justifyRight", "justifyFull","horizontalLine", "orderedList", "unorderedList"]]
    }
    console.log("Emails 1", this.data.ben_email1.trim(),"Emails 2", this.data.ben_email2.trim())
    if(!this.data.tipo){
      this.para = (this.data.ben_email1.trim() != "") ? this.data.ben_email1.trim() : ""
      this.para += (this.data.ben_email2.trim() != "") ?  ","+this.data.ben_email2.trim() : ""
      this.asunto = this.asunto + this.data.cab_codigo.slice(0,3)+'-'+this.data.cab_codigo.slice(3,6)+'-'+this.data.cab_codigo.slice(9,20)
      this.onCuerpoMail(this.data.cab_codigopk, this.data.doc_grupo)
    }
    
  }
  onCuerpoMail(idDocumento :String, tipoDocumento:String){
    this.documentosService.cuerpoMail(idDocumento, tipoDocumento).subscribe(
      result => {
        console.log("cuerpo", result)
        this.cuerpo = result.empCuerpoCorreo
      }
    )
  }

  public options: Object = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false,
    toolbarButtons:['bold','italic']
  }

  validarMail(){
    if(this.para == ''){
      this.toastr.error("No ingreso el destinatorio")
      return false
    }
    if(this.asunto == ''){
      this.toastr.warning("No ingreso el  asunto")
      return false
    }

    if(this.cuerpo == ''){
      this.toastr.warning("No ingreso el  mensaje para enviar")
      return false
    }

    return true
  }
  onEnviarMail(){
    if(this.validarMail()){
      this.hidden = false
      this.documentosService.enviarmail(this.data.cab_codigopk,this.para,this.asunto,this.data.cab_estado_electronico, this.cuerpo, this.data.doc_grupo).subscribe(
        item=>{
          this.toastr.success('Exito','Email enviado')
          this.hidden = true
        }
      )
    }
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
