
    <h1 mat-dialog-title class="border-bottom border-primary" style="color:rgb(13, 71, 161)">Recortar Imagen</h1>
    <div mat-dialog-content>
      <div class="row m-0">
          <div class="col-12 col-xs-6 col-sm-6 ">
            <h2 class="titulo-imagen text-center">Imagen para cortar</h2>
            <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="false"
            [aspectRatio]="4 / 3"
            [resizeToWidth]="300"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (loadImageFailed)="loadImageFailed()">
          </image-cropper>
          </div>
          <div class="col-12 col-xs-6 col-sm-6 ">
            <h2 class="titulo-imagen text-center">Resultado de corte</h2>
            <img [src]="croppedImage" />
          </div>
      </div>
      <div class="row m-0 d-flex justify-content-center">
        <div class="col-12 col-xs-6 col-sm-6 ">
          <label id="#cortar" class="btn btn-primary btn-block mt-4">
            <i id="cortar" class="icofont icofont-image m-0"></i> 
            <mat-icon aria-label="" >add_photo_alternate</mat-icon> Subir imagen
            <input type="file" style="display:none" (change)="fileChangeEvent($event)" />
          </label>
        </div>
      </div>
    </div>
    
    <div mat-dialog-actions>
      <button mat-raised-button (click)="onNoClick()" color="accent"> 
        <mat-icon aria-label="" >close</mat-icon> Cancelar
      </button>
      <button mat-raised-button [mat-dialog-close]="croppedImage" cdkFocusInitial color="primary"> 
        <mat-icon aria-label="" >done_outline</mat-icon> Asignar
      </button>
    </div>
  